<template>
  <ValidationProvider
    v-slot="{ errors }"
    :name="schema.inputLabel"
    :rules="schema.required ? 'required' : ''"
  >
    <WrFormGroup
      :label="schema.inputLabel"
      style="flex: 1"
      help-color="red"
      is-error
      :help="errors && errors.length ? errors[0] : null"
    >
      <WrText weight="bold">{{ schema.inputLabel }}</WrText>
      <input v-model="value" type="hidden" />
      <ul>
        <li v-for="manualFile in manualFiles" :key="manualFile">
          <span>{{ getName(manualFile) }}</span>
        </li>
      </ul>
      <div v-if="aws">
        <div class="IconButton">
          <VueUpload
            ref="manual-upload"
            class="UploadImages"
            :post-action="aws.url"
            :multiple="true"
            :data="awsParams"
            extensions="png,jpg,jpeg,pdf"
            accept="image/png, image/jpeg, application/pdf"
            @input-file="manualInputFile"
            @input-filter="manualInputFilter"
          >
            <WrButton color="primary" :disabled="isSubmitting">
              <WrMaterialIcon
                name="upload"
                size="medium"
                class="pr-2"
                color="white"
                material-icon
              />
              Select Files
            </WrButton>
          </VueUpload>
          <WrButton
            color="primary"
            :disabled="isSubmitting || manualFiles.length === 0"
            style="margin-left: 10px"
            @click="clearFiles"
          >
            <WrMaterialIcon
              name="delete"
              size="medium"
              class="pr-2"
              color="white"
              material-icon
            />
            Clear Files
          </WrButton>
        </div>
      </div>
    </WrFormGroup>
  </ValidationProvider>
</template>

<script>
import { WrMaterialIcon, WrFormGroup, WrButton, WrText } from 'werecover-ui-lib'
import { abstractField } from 'vue-form-generator'
import VueUpload from 'vue-upload-component'
import AWS_QUERY from '~/apollo/queries/patient/aws.gql'
import { v4 as uuidv4 } from 'uuid'

const CaptureState = {
  IDLE: 'idle',
  SUBMITTING_MANUAL: 'submitting_manual',
  ERROR: 'error',
}

export default {
  components: {
    WrFormGroup,
    VueUpload,
    WrMaterialIcon,
    WrButton,
    WrText,
  },
  mixins: [abstractField],
  data() {
    return {
      awsFilenames: [],
      manualFiles: [],
      state: CaptureState.IDLE,
    }
  },
  computed: {
    awsParams() {
      return {
        bucket: this.aws.bucket,
        policy: this.aws.policy,
        'X-Amz-Algorithm': 'AWS4-HMAC-SHA256',
        'X-Amz-Credential': this.aws.credentials,
        'X-Amz-Date': this.aws.date,
        'X-Amz-Signature': this.aws.signature,
      }
    },
    patient() {
      return (
        this.formOptions?.inquiryPatient ?? {
          id: uuidv4(),
        }
      )
    },
    isSubmitting() {
      return this.state === CaptureState.SUBMITTING_MANUAL
    },
  },
  watch: {
    awsFilenames: {
      handler(value) {
        if (value.length === this.manualFiles.length) {
          this.state = CaptureState.IDLE
        }
        this.value = value.length > 0 ? JSON.stringify(value) : null
      },
      deep: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.value) {
        this.awsFilenames = JSON.parse(this.value)
        this.manualFiles = JSON.parse(this.value)
      }
    })
  },
  methods: {
    getName(name) {
      if (name.length > 40) {
        const extension = name.split('.').pop()
        return `${name.substring(0, 40)}...${extension}`
      }
      return name
    },
    async manualInputFilter(newFile, oldFile) {
      if (newFile && !oldFile) {
        // If we are here the object passed validation, start the upload
        newFile.data['Content-Type'] = newFile.type
        newFile.data.key = `patient/${this.patient.id}/uploads/${newFile.name}`
        this.manualFiles.push(newFile.name)
        this.$refs['manual-upload'].active = true
        this.state = CaptureState.SUBMITTING_MANUAL
      }
    },
    clearFiles() {
      this.manualFiles = []
      this.awsFilenames = []
      this.$refs['manual-upload']?.clear()
    },
    manualInputFile(newFile, oldFile) {
      // Update file
      if (newFile && oldFile) {
        // Upload error
        if (newFile.error !== oldFile.error) {
          this.$toast.error('Error uploading the files, please try again.', {
            hideProgressBar: true,
          })
          this.state = CaptureState.ERROR
        }
        // Uploaded successfully
        if (newFile.success !== oldFile.success) {
          this.$toast.success('File uploaded successfully.', {
            hideProgressBar: true,
          })
          this.awsFilenames.push(`${this.aws.url}/${newFile.data.key}`)
        }
      }
    },
  },
  apollo: {
    aws: {
      query() {
        return AWS_QUERY
      },
      fetchPolicy: 'network-only',
      update(data) {
        if (
          data &&
          data.metadata &&
          data.metadata.global &&
          data.metadata.global.aws
        ) {
          return data.metadata.global.aws
        }
      },
    },
  },
}
</script>
<style>
.IconButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  width: 100%;
  .pr-2 {
    padding-right: 5px;
  }
}
</style>
