<template>
  <ValidationProvider
    v-slot="{ errors }"
    :name="schema.inputLabel"
    :rules="schema.required ? 'required' : ''"
  >
    <WrFormGroup
      help-color="red"
      is-error
      style="flex: 1"
      :help="errors && errors.length ? errors[0] : null"
    >
      <WrTextArea
        v-model="value"
        resize
        :label="schema.inputLabel"
        :rows="5"
        :placeholder="schema.placeholder"
      />
    </WrFormGroup>
  </ValidationProvider>
</template>

<script>
import { WrTextArea, WrFormGroup } from 'werecover-ui-lib'
import { abstractField } from 'vue-form-generator'

export default {
  components: {
    WrTextArea,
    WrFormGroup,
  },
  mixins: [abstractField],
}
</script>
