/* eslint-disable */

import Vue from 'vue'

export default ({ app }) => {
  var store = require('store')
  var expirePlugin = require('store/plugins/expire')
  store.addPlugin(expirePlugin)
  Vue.prototype.$storejs = Vue.$storejs = store
}
