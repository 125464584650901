<template>
  <ValidationProvider
    v-slot="{ errors }"
    :name="schema.inputLabel"
    :rules="schema.required ? 'required|email' : 'email'"
  >
    <WrFormGroup
      help-color="red"
      is-error
      style="flex: 1"
      :help="errors && errors.length ? errors[0] : null"
    >
      <WrInput
        v-model="value"
        :label="schema.inputLabel"
        placeholder="example@domain.com"
        type="email"
      />
    </WrFormGroup>
  </ValidationProvider>
</template>

<script>
import { WrInput, WrFormGroup } from 'werecover-ui-lib'
import { abstractField } from 'vue-form-generator'

export default {
  components: {
    WrInput,
    WrFormGroup,
  },
  mixins: [abstractField],
  mounted() {
    if (this.formOptions?.inquiryPatient?.email) {
      this.value = this.formOptions?.inquiryPatient?.email
    }
  },
}
</script>
