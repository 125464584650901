<template>
  <ValidationProvider
    v-slot="{ errors }"
    :name="schema.inputLabel"
    :rules="schema.required ? 'required' : ''"
  >
    <WrFormGroup
      help-color="red"
      is-error
      style="flex: 1"
      :help="errors && errors.length ? errors[0] : null"
      label="Legal Sex"
    >
      <WrSelect
        id="gender"
        v-model="value"
        :label="schema.inputLabel"
        :options="genderOptions"
      />
    </WrFormGroup>
  </ValidationProvider>
</template>

<script>
import { WrSelect, WrFormGroup } from 'werecover-ui-lib'
import { abstractField } from 'vue-form-generator'

export default {
  components: {
    WrSelect,
    WrFormGroup,
  },
  mixins: [abstractField],
  mounted() {
    if (this.formOptions?.inquiryPatient?.gender) {
      this.value = this.formOptions?.inquiryPatient?.gender
    }
  },
  computed: {
    genderOptions() {
      return [
        {
          value: 'MALE',
          description: 'Male',
        },
        {
          value: 'FEMALE',
          description: 'Female',
        },
      ]
    },
  },
}
</script>
