<template>
  <WrFormGroup help-color="red" is-error style="flex: 1">
    <WrText weight="bold">{{ schema.inputLabel }}</WrText>
    <WrText size="small" class="text-pre">{{ schema.description }}</WrText>
  </WrFormGroup>
</template>

<script>
import { WrText, WrFormGroup } from 'werecover-ui-lib'
import { abstractField } from 'vue-form-generator'

export default {
  components: {
    WrText,
    WrFormGroup,
  },
  mixins: [abstractField],
}
</script>
<style scoped>
.text-pre {
  white-space: pre-line;
}
</style>
