<template>
  <div>
    <ValidationProvider
      v-slot="{ errors }"
      :name="schema.inputLabel"
      :rules="schema.required ? 'required' : ''"
    >
      <WrFormGroup
        label-for="insuranceCompany"
        bottom="medium"
        help-color="red"
        is-error
        :help="errors && errors.length ? errors[0] : null"
      >
        <input v-model="internalValue[0]" type="hidden" />
        <WrSoftSelectInput
          :label="schema.inputLabel"
          placeholder="Select Insurance Company"
          :options="insuranceCompanyOptions"
          :selected="selectedOption"
          cta-text="Select"
          show-cta
          :search-threshold="0.3"
          @onSelect="onInsuranceCompanySelected"
        ></WrSoftSelectInput>
      </WrFormGroup>
    </ValidationProvider>
    <ValidationProvider
      v-slot="{ errors }"
      name="member ID"
      :rules="schema.required ? 'required' : ''"
    >
      <WrFormGroup
        bottom="medium"
        help-color="red"
        is-error
        :help="errors && errors.length ? errors[0] : null"
      >
        <WrInput
          id="memberNumber"
          v-model="internalValue[1]"
          label="Member ID"
          name="memberID"
          placeholder="123456789"
        />
      </WrFormGroup>
    </ValidationProvider>
  </div>
</template>

<script>
import { abstractField } from 'vue-form-generator'
import { WrInput, WrFormGroup, WrSoftSelectInput } from 'werecover-ui-lib'

export default {
  components: {
    WrInput,
    WrFormGroup,
    WrSoftSelectInput,
  },
  mixins: [abstractField],
  prefetchMetadata: ['waystarInsuranceCompanies'],
  data() {
    return {
      internalValue: [null, ''],
    }
  },
  computed: {
    selectedOption() {
      return this.internalValue[0]?.id || null
    },
  },
  watch: {
    internalValue(newValue) {
      this.value = newValue
    },
  },
  mounted() {
    if (this.formOptions?.inquiryPatient?.insurancePolicy?.memberNumber) {
      this.internalValue[1] =
        this.formOptions?.inquiryPatient?.insurancePolicy?.memberNumber
    }
    if (
      this.formOptions?.inquiryPatient?.insurancePolicy?.insuranceCompany?.id &&
      this.formOptions?.inquiryPatient?.insurancePolicy?.insuranceCompany?.name
    ) {
      this.internalValue[0] = {
        ...this.formOptions?.inquiryPatient?.insurancePolicy?.insuranceCompany,
        id: String(
          this.formOptions?.inquiryPatient?.insurancePolicy?.insuranceCompany
            ?.id,
        ),
      }
    }
  },
  methods: {
    onInsuranceCompanySelected(insuranceCompanyId) {
      this.internalValue[0] = this.insuranceCompanyOptions.find(
        (x) => x.id === insuranceCompanyId,
      )
    },
  },
  computed: {
    insuranceCompanyOptions() {
      if (!this.waystarInsuranceCompanies) return []
      return this.waystarInsuranceCompanies.map((x) => {
        return { value: String(x.id), name: x.name, id: String(x.id) }
      })
    },
  },
}
</script>
