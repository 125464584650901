import Vue from 'vue'
const AppLocalstorage = {
  install(Vue, options) {
    Vue.prototype.$isLocalstorageAvailable = function () {
      const key = 'key'
      const value = 'values'
      try {
        localStorage.setItem(key, value)
        var recoveredValue = localStorage.getItem(key)
        localStorage.removeItem(value)
        return recoveredValue === value
      } catch (e) {
        return false
      }
    }
  },
}
Vue.use(AppLocalstorage)
export default AppLocalstorage
