<template>
  <ValidationProvider
    v-slot="{ errors }"
    :name="schema.inputLabel"
    :rules="schema.required ? 'required|phone' : ''"
  >
    <WrFormGroup
      help-color="red"
      is-error
      style="flex: 1"
      :help="errors && errors.length ? errors[0] : null"
    >
      <WrInput
        v-model="value"
        :label="schema.inputLabel"
        placeholder="(000) 000-0000"
        mask="(999) 999-9999"
        type="tel"
        pattern="\d*"
      />
    </WrFormGroup>
  </ValidationProvider>
</template>

<script>
import { WrInput, WrFormGroup } from 'werecover-ui-lib'
import { abstractField } from 'vue-form-generator'

export default {
  components: {
    WrInput,
    WrFormGroup,
  },
  mixins: [abstractField],
  mounted() {
    if (this.formOptions?.inquiryPatient?.contactPhoneNumber) {
      this.value = this.formOptions?.inquiryPatient?.contactPhoneNumber
    }
  },
}
</script>
