import Vue from 'vue'
import Multiselect from 'vue-multiselect'
const VueMultiselect = {
  install(Vue, options) {
    Vue.component('VueMultiselect', Multiselect)
  },
}

if (
  Multiselect &&
  Multiselect.mixins &&
  Multiselect.mixins[0] &&
  Multiselect.mixins[0].methods
) {
  Multiselect.mixins[0].methods.activate = function () {
    /* istanbul ignore else */
    if (this.isOpen || this.disabled) return

    this.adjustPosition()
    /* istanbul ignore else  */
    if (this.groupValues && this.pointer === 0 && this.filteredOptions.length) {
      this.pointer = 1
    }

    this.isOpen = true
    /* istanbul ignore else  */
    if (this.searchable) {
      if (!this.preserveSearch) this.search = ''
      this.$nextTick(() => this.$refs.search && this.$refs.search.focus())
    } else {
      this.$el.focus()
    }
    this.$emit('open', this.id)
  }

  Multiselect.mixins[0].methods.deactivate = function () {
    /* istanbul ignore else */
    if (!this.isOpen) return

    this.isOpen = false
    /* istanbul ignore else  */
    if (this.searchable) {
      this.$refs.search && this.$refs.search.blur()
    } else {
      this.$el.blur()
    }
    if (!this.preserveSearch) this.search = ''
    this.$emit('close', this.getValue(), this.id)
  }
} else {
  console.warn(
    'Cannot apply patch to vue-multiselect. Error with `blur` might appear again',
  )
}
Vue.use(VueMultiselect)
export default VueMultiselect
