<template>
  <div v-if="isLoading" class="FullCapture"><WrLoading static /></div>
  <div v-else-if="isErrored" class="FullCapture">
    <WrText size="medium" weight="bold">
      Please upload your images manually
    </WrText>
    <ul>
      <li v-for="manualFile in manualFiles" :key="manualFile.id">
        <WrLoading static />
      </li>
    </ul>
    <div class="IconButton">
      <div>
        <div class="IconButton">
          <VueUpload
            ref="manual-upload"
            v-model="manualFiles"
            :input-id="`manual-upload-${model}`"
            class="UploadImages"
            :post-action="aws.url"
            :multiple="true"
            :maximum="1"
            :data="awsParams"
            extensions="png,jpg,jpeg,pdf"
            accept="image/png, image/jpeg, application/pdf"
            @input-file="manualInputFile"
            @input-filter="manualInputFilter"
          >
            <WrMaterialIcon
              name="upload"
              size="medium"
              class="pr-2"
              color="white"
              material-icon
            />
            Select images
          </VueUpload>
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="!isIdle" class="FullCapture">
    <div
      v-show="!isFinished && state !== 'finished'"
      class="FullCapture_Camera"
    >
      <div class="FullCapture_Camera_Stream">
        <video
          id="FullCapture_Camera_Video"
          ref="FullCapture_Camera_Video"
          class="FullCapture_Camera_Video"
          autoplay
          playsinline
        ></video>
      </div>
      <div class="FullCapture_Camera_Controls">
        <WrButton color="primary" size="medium" @click="captureImage">
          <div class="IconButton">
            <WrMaterialIcon
              name="camera"
              size="medium"
              class="pr-2"
              color="white"
              material-icon
            />
            Capture Image
          </div>
        </WrButton>
        <WrButton color="primary" size="medium" @click="switchCamera">
          <div class="IconButton">
            <WrMaterialIcon
              name="cameraswitch"
              size="medium"
              class="pr-2"
              color="white"
              material-icon
            />
            Switch Camera
          </div>
        </WrButton>
      </div>
    </div>
    <div
      v-if="state !== 'finished'"
      v-show="isFinished"
      class="FullCapture_Pictures_Wrapper"
    >
      <div class="FullCapture_Pictures">
        <div v-if="capturedImage">
          <img :src="capturedImage" />
        </div>
      </div>
      <div class="FullCapture_Camera_Controls">
        <WrButton color="primary" size="medium" @click="restartCamera">
          <div class="IconButton">
            <WrMaterialIcon
              name="photo_camera"
              size="medium"
              class="pr-2"
              color="white"
              material-icon
            />
            Recapture Image
          </div>
        </WrButton>
        <VueUpload
          ref="upload"
          v-model="files"
          :input-id="`camera-upload-${model}`"
          :post-action="aws.url"
          :multiple="true"
          :maximum="2"
          :data="awsParams"
          @input-file="inputFile"
          @input-filter="inputFilter"
        ></VueUpload>
        <WrButton color="primary" size="medium" @click="submitImages">
          <div class="IconButton">
            <WrMaterialIcon
              name="upload"
              size="medium"
              class="pr-2"
              color="white"
              material-icon
            />
            <WrText weight="semibold" color="white">Submit image</WrText>
          </div>
        </WrButton>
      </div>
    </div>
    <div v-show="state === 'finished'" class="FullCapture_Pictures_Wrapper">
      <div class="FullCapture_Pictures">
        <div v-if="capturedImage">
          <img :src="capturedImage" />
        </div>
        <div v-else-if="awsFilenames[0]">
          <img v-if="!previewIsPDF" :src="awsFilenames[0]" />
          <span v-else>
            <a :href="awsFilenames[0]" target="_blank">View PDF</a>
          </span>
        </div>
      </div>
      <div class="FullCapture_Camera_Controls">
        <WrButton color="primary" size="medium" @click="restartComponent">
          <div class="IconButton">
            <WrMaterialIcon
              name="photo_camera"
              size="medium"
              class="pr-2"
              color="white"
              material-icon
            />
            Recapture Image
          </div>
        </WrButton>
      </div>
    </div>
  </div>
  <div v-else class="FullCapture">
    <WrButton
      icon="photo_camera"
      material-icon
      color="primary"
      @click="startCamera"
    >
      <div class="IconButton">
        <WrMaterialIcon
          name="photo_camera"
          size="medium"
          class="pr-2"
          color="white"
          material-icon
        />
        Start Image Capture
      </div>
    </WrButton>
    <button class="TextButton" @click="initializeFilePicker">
      Or Upload Image
    </button>
  </div>
</template>
<script>
import { WrButton, WrLoading, WrMaterialIcon, WrText } from 'werecover-ui-lib'
import VueUpload from 'vue-upload-component'
import AWS_QUERY from '~/apollo/queries/patient/aws.gql'
import Vue from 'vue'

const CaptureState = {
  IDLE: 'idle',
  REQUESTING_PERMISSION: 'requesting_permission',
  CAPTURING: 'capturing',
  CAPTURED: 'captured',
  SUBMITTING: 'submitting',
  SUBMITTING_MANUAL: 'submitting_manual',
  ERROR: 'error',
  FINISHED: 'finished',
}

export default {
  name: 'BackCamera',
  components: {
    WrButton,
    WrLoading,
    WrMaterialIcon,
    WrText,
    VueUpload,
  },
  emits: ['finish'],
  props: {
    model: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      video: null,
      capturedImage: null,
      stream: null,
      useFrontCamera: false,
      state: CaptureState.IDLE,
      files: [],
      awsFilenames: [],
      manualFiles: [],
      manualFilesCount: 0,
    }
  },
  computed: {
    isIdle() {
      return this.state === CaptureState.IDLE
    },
    isLoading() {
      return (
        this.state === CaptureState.REQUESTING_PERMISSION ||
        this.state === CaptureState.SUBMITTING
      )
    },
    previewIsPDF() {
      return this.awsFilenames?.[0]?.includes('pdf')
    },
    isErrored() {
      return (
        this.state === CaptureState.ERROR ||
        this.state === CaptureState.SUBMITTING_MANUAL
      )
    },
    isSubmitting() {
      return (
        this.state === CaptureState.SUBMITTING ||
        this.state === CaptureState.SUBMITTING_MANUAL
      )
    },
    isFinished() {
      return this.state === CaptureState.CAPTURED
    },
    awsParams() {
      return {
        bucket: this.aws.bucket,
        policy: this.aws.policy,
        'X-Amz-Algorithm': 'AWS4-HMAC-SHA256',
        'X-Amz-Credential': this.aws.credentials,
        'X-Amz-Date': this.aws.date,
        'X-Amz-Signature': this.aws.signature,
      }
    },
  },
  watch: {
    capturedImage: {
      handler() {
        if (this.capturedImage) {
          this.stopCamera()
          this.addBase64Image(this.capturedImage, `picture-${this.uuid()}.png`)
        }
      },
      deep: true,
    },
    awsFilenames: {
      handler(value) {
        if (value?.length === 1) {
          this.$emit('finish', value[0])
          this.state = CaptureState.FINISHED
        }
      },
      deep: true,
    },
  },
  beforeUnmount() {
    this.stopCamera()
  },
  methods: {
    restartComponent() {
      this.$emit('finish', null)
      this.clearEverything()
    },
    startManualUpload() {
      this.state = CaptureState.SUBMITTING_MANUAL
      this.$refs['manual-upload'].active = true
    },
    initializeFilePicker() {
      this.state = CaptureState.ERROR
    },
    switchCamera() {
      this.useFrontCamera = !this.useFrontCamera
      this.startCamera()
    },
    captureImage() {
      const canvas = document.createElement('canvas')
      canvas.width = this.video.videoWidth
      canvas.height = this.video.videoHeight
      const ctx = canvas.getContext('2d')

      ctx.drawImage(
        this.video,
        0,
        0,
        this.video.videoWidth,
        this.video.videoHeight,
        0,
        0,
        this.video.videoWidth,
        this.video.videoHeight,
      )
      this.capturedImage = canvas.toDataURL('image/png')
      this.$toast.success('Image captured successfully.', {
        hideProgressBar: true,
      })
      this.state = CaptureState.CAPTURED
    },
    startCamera() {
      this.state = CaptureState.REQUESTING_PERMISSION
      navigator.mediaDevices
        .getUserMedia({
          video: {
            facingMode: this.useFrontCamera ? 'user' : 'environment',
            width: { ideal: window.innerWidth },
            height: { ideal: window.innerHeight },
          },
        })
        .then((stream) => {
          this.state = CaptureState.CAPTURING
          Vue.nextTick(() => {
            this.video = this.$refs.FullCapture_Camera_Video
            this.video.srcObject = stream
            this.stream = stream
          })
        })
        .catch((error) => {
          this.state = CaptureState.ERROR
          console.error('Error accediendo a la cámara: ', error)
        })
    },
    restartCamera() {
      this.capturedImage = null
      this.startCamera()
    },
    stopCamera() {
      if (this.stream) {
        this.stream.getTracks().forEach((track) => {
          track.stop()
        })
      }
    },
    submitImages() {
      this.$refs.upload.active = true
    },
    uuid() {
      var d = new Date().getTime()
      if (
        typeof performance !== 'undefined' &&
        typeof performance.now === 'function'
      ) {
        d += performance.now() // use high-precision timer if available
      }
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
        /[xy]/g,
        function (c) {
          var r = (d + Math.random() * 16) % 16 | 0
          d = Math.floor(d / 16)
          return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
        },
      )
    },
    addBase64Image(base64Data, imageName) {
      // Convert base64 to Blob
      const byteCharacters = atob(base64Data.split(',')[1])
      const byteNumbers = new Array(byteCharacters.length)
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
      }
      const byteArray = new Uint8Array(byteNumbers)
      const blob = new Blob([byteArray], { type: 'image/png' })
      this.$refs.upload.clear()
      this.awsFilenames = []

      // Add the blob as a file to the files array
      this.$refs.upload.add({
        name: imageName,
        type: 'image/png',
        size: blob.size,
        dataUrl: base64Data,
        file: blob,
      })
    },
    inputFile(newFile, oldFile) {
      // Update file
      if (newFile && oldFile) {
        // Upload error
        if (newFile.error !== oldFile.error) {
          this.$toast.error('Error uploading the image, please try again.', {
            hideProgressBar: true,
          })
          this.state = CaptureState.CAPTURED
        }
        // Uploaded successfully
        if (newFile.success !== oldFile.success) {
          this.$toast.success('Image uploaded successfully.', {
            hideProgressBar: true,
          })
          this.awsFilenames.push(`${this.aws.url}/${newFile.data.key}`)
        }
      }
    },
    async inputFilter(newFile, oldFile) {
      if (newFile && !oldFile) {
        // If we are here the object passed validation, start the upload
        const uuid = this.uuid()
        newFile.data['Content-Type'] = newFile.type
        newFile.data.key = `patient/${uuid}/uploads/${newFile.name}`
      }
    },
    async manualInputFilter(newFile, oldFile) {
      if (newFile && !oldFile) {
        // If we are here the object passed validation, start the upload
        const uuid = this.uuid()
        newFile.data['Content-Type'] = newFile.type
        newFile.data.key = `patient/${uuid}/uploads/${newFile.name}`
        this.capturedImage = newFile.dataUrl
      }
      this.updateCount()
      this.startManualUpload()
    },
    manualInputFile(newFile, oldFile) {
      // Update file
      if (newFile && oldFile) {
        // Upload error
        if (newFile.error !== oldFile.error) {
          this.$toast.error('Error uploading the image, please try again.', {
            hideProgressBar: true,
          })
          this.state = CaptureState.ERROR
        }
        // Uploaded successfully
        if (newFile.success !== oldFile.success) {
          this.$toast.success('Image uploaded successfully.', {
            hideProgressBar: true,
          })
          this.awsFilenames.push(`${this.aws.url}/${newFile.data.key}`)
        }
      }
      this.updateCount()
    },
    updateCount() {
      this.manualFilesCount = this.$refs['manual-upload'].files.length
    },
    clearEverything() {
      this.capturedImage = null
      this.awsFilenames = []
      this.manualFiles = []
      this.files = []
      this.manualFilesCount = 0
      this.$refs.upload?.clear()
      this.$refs['manual-upload']?.clear()
      this.state = CaptureState.IDLE
    },
  },
  apollo: {
    aws: {
      query() {
        return AWS_QUERY
      },
      fetchPolicy: 'network-only',
      update(data) {
        if (
          data &&
          data.metadata &&
          data.metadata.global &&
          data.metadata.global.aws
        ) {
          return data.metadata.global.aws
        }
      },
    },
  },
}
</script>
<style lang="scss" scoped>
.FullCapture {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  .TextButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
    font-size: 14px;
    font-weight: 500;
    color: #523dc8;
    cursor: pointer;
    background: none;
    border: none;
  }
  .UploadImages {
    display: flex;
    font-family: 'Roboto', serif !important;
    border-radius: 8px;
    border: none;
    text-align: center;
    background: #523dc8 !important;
    color: white;
    font-size: 16px;
    font-weight: 500;
    height: 48px;
    min-width: 48px;
    justify-content: center;
    align-items: center;
    padding: 0 8px;
  }
  .IconButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    .pr-2 {
      padding-right: 5px;
    }
  }
  &_Camera {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    &_Stream {
      position: relative;
      width: 100%;
      display: flex;
      justify-content: center;
    }
    &_Video {
      max-width: 800px;
      max-height: 450px;
      width: 100%;
      height: auto;
      aspect-ratio: 1920 / 1080;
    }
    &_Overlay {
      position: absolute;
      border: 2px dashed red;
      max-width: 530px;
      max-height: 350px;
      width: 90%;
      height: auto;
      aspect-ratio: 1920 / 1080;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      &_Text {
        position: absolute;
        top: -20px;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
        text-shadow: 0px 0px 10px black;
      }
    }
    &_Controls {
      padding-top: 20px;
      display: flex;
      justify-content: space-around;
      width: 100%;
    }
  }
  &_Pictures {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      max-width: 600px;
      max-height: 400px;
      width: 100%;
      height: auto;
      border: 2px solid white;
      margin-right: 5px;
    }
  }
  &_Pictures_Wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
</style>
