<template>
  <ValidationProvider
    v-slot="{ errors }"
    :name="schema.inputLabel"
    :rules="schema.required ? 'required' : ''"
  >
    <WrFormGroup
      help-color="red"
      is-error
      style="flex: 1"
      :help="errors && errors.length ? errors[0] : null"
    >
      <vue-timepicker
        v-model="internalValue"
        format="hh:mm A"
        :minute-interval="30"
        hour-label=""
        minute-label=""
        apm-label=""
        :placeholder="schema.inputLabel"
      ></vue-timepicker>
    </WrFormGroup>
  </ValidationProvider>
</template>

<script>
import { WrFormGroup } from 'werecover-ui-lib'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
import { abstractField } from 'vue-form-generator'
export default {
  components: {
    WrFormGroup,
    VueTimepicker,
  },
  mixins: [abstractField],
  data() {
    return {
      internalValue: '',
    }
  },
  watch: {
    internalValue(newValue) {
      this.value = newValue
    },
  },
  mounted() {
    this.internalValue = this.value ?? ''
  },
}
</script>
<style>
.vue__time-picker-dropdown {
  z-index: 9000;
}
.vue__time-picker,
.vue__time-picker > input {
  width: 100% !important;
}

.vue__time-picker > input {
  border-radius: 8px;
  height: 56px !important;
  border: 1px solid #afafaf !important;
  color: #525a5d !important;
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
  outline: none !important;
}
.vue__time-picker > input:focus {
  border-color: #523dc8 !important;
}
.vue__time-picker .dropdown ul li:not([disabled]).active {
  background: #523dc8 !important;
}
</style>
