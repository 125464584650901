<template>
  <ValidationProvider
    v-slot="{ errors }"
    :name="schema.inputLabel"
    :rules="schema.required ? { required: { allowFalse: false } } : ''"
  >
    <WrFormGroup
      help-color="red"
      is-error
      :help="errors && errors.length ? errors[0] : null"
    >
      <WrCheckbox :id="schema.model" v-model="value">
        {{ schema.inputLabel }}
      </WrCheckbox>
    </WrFormGroup>
  </ValidationProvider>
</template>

<script>
import { WrCheckbox, WrFormGroup } from 'werecover-ui-lib'
import { abstractField } from 'vue-form-generator'

export default {
  components: {
    WrCheckbox,
    WrFormGroup,
  },
  mixins: [abstractField],
}
</script>
