<template>
  <ValidationProvider
    v-slot="{ errors }"
    :name="schema.inputLabel"
    :rules="schema.required ? 'required' : ''"
  >
    <WrFormGroup
      help-color="red"
      is-error
      style="flex: 1"
      :help="errors && errors.length ? errors[0] : null"
    >
      <WrSelect
        v-model="value"
        :label="schema.inputLabel"
        :options="formattedPaymentMethods"
      />
    </WrFormGroup>
  </ValidationProvider>
</template>

<script>
import { WrSelect, WrFormGroup } from 'werecover-ui-lib'
import { abstractField } from 'vue-form-generator'
import state from '~/apollo/state'

export default {
  components: {
    WrSelect,
    WrFormGroup,
  },
  mixins: [abstractField],
  prefetchMetadata: ['patientPaymentMethods'],
  computed: {
    formattedPaymentMethods() {
      if (this.provider.canShowOnlyMyPaymentMethods) {
        return this.provider.paymentMethods.length > 0
          ? this.formatPaymentMethods(
              this.provider.paymentMethods,
              this.patientPaymentMethods,
            )
          : this.patientPaymentMethods
          ? this.patientPaymentMethods
          : []
      } else {
        return this.patientPaymentMethods ? this.patientPaymentMethods : []
      }
    },
    provider() {
      return this.formOptions?.provider
        ? this.formOptions.provider
        : this.formOptions?.inquiryPatient?.creatorProvider
        ? this.formOptions?.inquiryPatient?.creatorProvider
        : state.currentProvider
    },
  },
  mounted() {
    if (this.formOptions?.inquiryPatient?.paymentMethod) {
      this.value = this.formOptions?.inquiryPatient?.paymentMethod
    }
  },
  methods: {
    formatPaymentMethods(providerPaymentMethods, paymentMethodsMeta) {
      return providerPaymentMethods.map((providerPaymentMethod) => {
        return {
          value: providerPaymentMethod.name,
          description: paymentMethodsMeta?.filter(
            (paymentMethod) =>
              paymentMethod.value === providerPaymentMethod.name,
          )[0]?.description,
        }
      })
    },
  },
}
</script>
