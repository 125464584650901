import Vue from 'vue'
import Cloudinary, {
  CldImage,
  CldContext,
  CldTransformation,
} from 'cloudinary-vue'

Vue.use(Cloudinary, {
  secure: true,
  configuration: { cloudName: 'werecover' },
  components: [CldImage, CldContext, CldTransformation],
})
