/* eslint-disable */

import Vue from 'vue'

export default ({ app }) => {

  Vue.prototype.$gtm = Vue.$gtm = {
    dataLayer: {
      push (data) {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push(data)
      }
    }
  }
}
