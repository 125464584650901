<template>
  <ValidationProvider
    v-slot="{ errors }"
    :name="schema.inputLabel"
    :rules="schema.required ? 'required' : ''"
  >
    <WrFormGroup
      help-color="red"
      is-error
      style="flex: 1"
      :help="errors && errors.length ? errors[0] : null"
    >
      <input :key="`hidden-${key}`" v-model="value" type="hidden" />
      <WrText weight="bold">{{ schema.inputLabel }}</WrText>
      <BackCamera :model="schema.model" @finish="onImageUploaded" />
    </WrFormGroup>
  </ValidationProvider>
</template>

<script>
import { abstractField } from 'vue-form-generator'
import { WrFormGroup, WrText } from 'werecover-ui-lib'
import TrackWindowSize from '~/mixins/TrackWindowSize'
import BackCamera from './utils/BackCamera'

export default {
  components: {
    WrFormGroup,
    WrText,
    BackCamera,
  },
  mixins: [abstractField, TrackWindowSize],
  data() {
    return {
      key: 0,
    }
  },
  methods: {
    onImageUploaded(imageUrl) {
      this.value = imageUrl
      this.key += 1
    },
  },
}
</script>
