<template>
  <ValidationProvider
    v-slot="{ errors }"
    :name="schema.inputLabel"
    :rules="schema.required ? 'required|min:1' : ''"
  >
    <WrFormGroup
      help-color="red"
      is-error
      style="flex: 1"
      :help="errors && errors.length ? errors[0] : null"
    >
      <input v-model="value" type="hidden" />
      <WrSoftSelectInput
        :label="schema.inputLabel"
        :selected="value"
        :placeholder="schema.inputLabel"
        :options="formattedIssues"
        multiple
        @onSelect="onSelected"
      />
    </WrFormGroup>
  </ValidationProvider>
</template>

<script>
import { WrSoftSelectInput, WrFormGroup } from 'werecover-ui-lib'
import { abstractField } from 'vue-form-generator'
import INQUIRY_PATIENT_QUERY from '~/apollo/queries/patient/inquiryPatient'
import ISSUES_QUERY from '~/apollo/queries/metadata/issues'
import state from '~/apollo/state'

export default {
  components: {
    WrSoftSelectInput,
    WrFormGroup,
  },
  mixins: [abstractField],
  computed: {
    formattedIssues() {
      console.log(this.provider)
      if (!this.issues) {
        return []
      }
      if (this.provider?.canShowOnlyMyIssues) {
        return this.provider.issues.map(this.standarizeIssue)
      } else {
        const providerSelectedIssues = this.provider.issues.map(
          this.standarizeIssue,
        )

        const ids = new Set(this.issues.map((d) => d.id))
        return [
          ...this.issues,
          ...providerSelectedIssues.filter((d) => !ids.has(d.id)),
        ].sort((a, b) => (a.name > b.name ? 1 : -1))
      }
    },
    provider() {
      return this.formOptions?.provider
        ? this.formOptions.provider
        : this.inquiryPatient?.creatorProvider
        ? this.inquiryPatient.creatorProvider
        : state.currentProvider
    },
  },
  mounted() {
    if (this.formOptions?.inquiryPatient?.issues) {
      const issues = this.formOptions.inquiryPatient.issues
      if (Array.isArray(issues) && issues.length > 0) {
        this.value = this.formOptions.inquiryPatient.issues.map((issue) =>
          String(issue.id),
        )
      }
    }
  },
  methods: {
    onSelected(options) {
      this.value = options
    },
    standarizeIssue(issue) {
      return {
        id: String(issue.id),
        name: issue.name,
        value: issue.id,
      }
    },
  },
  apollo: {
    inquiryPatient: {
      query: INQUIRY_PATIENT_QUERY,
      skip() {
        return (
          !this.formOptions.patientToken || !this.formOptions.conversationId
        )
      },
      variables() {
        return { inquiryToken: this.formOptions.patientToken }
      },
    },
    issues: {
      query: ISSUES_QUERY,
      variables() {
        return {
          providerTypeId: [this.provider.providerType.id],
          orderBy: {
            field: 'NAME',
          },
        }
      },
      update(data) {
        if (!data.issues) return []
        return data.issues.map(this.standarizeIssue)
      },
    },
  },
}
</script>
