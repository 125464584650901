import Vue from 'vue'
import VueFormGenerator from 'vue-form-generator'
import fieldWrInput from '../components/CustomFormFields/fieldWrInput'
import fieldWrSelect from '../components/CustomFormFields/fieldWrSelect'
import fieldWrCheckbox from '../components/CustomFormFields/fieldWrCheckbox'
import fieldWrTextArea from '../components/CustomFormFields/fieldWrTextArea'
import fieldWrText from '../components/CustomFormFields/fieldWrText'
import fieldWrSoftSelectInput from '../components/CustomFormFields/fieldWrSoftSelectInput'
import fieldIssuesPicker from '../components/CustomFormFields/fieldIssuesPicker'
import fieldPhoneInput from '../components/CustomFormFields/fieldPhoneInput'
import fieldEmailInput from '../components/CustomFormFields/fieldEmailInput'
import fieldDobInput from '../components/CustomFormFields/fieldDobInput'
import fieldGenderInput from '../components/CustomFormFields/fieldGenderInput'
import fieldPaymentMethods from '../components/CustomFormFields/fieldPaymentMethods'
import fieldHiddenField from '../components/CustomFormFields/fieldHiddenField'
import fieldFirstLastName from '../components/CustomFormFields/fieldFirstLastName'
import fieldSignaturePad from '../components/CustomFormFields/fieldSignaturePad'
import fieldDateInput from '../components/CustomFormFields/fieldDateInput'
import fieldInsuranceInput from '../components/CustomFormFields/fieldInsuranceInput'
import fieldBackCamera from '../components/CustomFormFields/fieldBackCamera'
import fieldMultifileInput from '../components/CustomFormFields/fieldMultifileInput'
import fieldTimePicker from '../components/CustomFormFields/fieldTimePicker'

Vue.component('FieldWrInput', fieldWrInput)
Vue.component('FieldWrSelect', fieldWrSelect)
Vue.component('FieldWrCheckbox', fieldWrCheckbox)
Vue.component('FieldWrTextArea', fieldWrTextArea)
Vue.component('FieldWrText', fieldWrText)
Vue.component('FieldWrSoftSelectInput', fieldWrSoftSelectInput)
Vue.component('FieldIssuesPicker', fieldIssuesPicker)
Vue.component('FieldPhoneInput', fieldPhoneInput)
Vue.component('FieldEmailInput', fieldEmailInput)
Vue.component('FieldDobInput', fieldDobInput)
Vue.component('FieldGenderInput', fieldGenderInput)
Vue.component('FieldPaymentMethods', fieldPaymentMethods)
Vue.component('FieldHiddenField', fieldHiddenField)
Vue.component('FieldFirstLastName', fieldFirstLastName)
Vue.component('FieldSignaturePad', fieldSignaturePad)
Vue.component('FieldDateInput', fieldDateInput)
Vue.component('FieldInsuranceInput', fieldInsuranceInput)
Vue.component('FieldBackCamera', fieldBackCamera)
Vue.component('FieldMultifileInput', fieldMultifileInput)
Vue.component('FieldTimePicker', fieldTimePicker)
Vue.use(VueFormGenerator)
