<template>
  <div>
    <ValidationProvider
      v-slot="{ errors }"
      name="First Name"
      :rules="schema.required ? 'required' : ''"
    >
      <WrFormGroup
        help-color="red"
        is-error
        style="flex: 1"
        :help="errors && errors.length ? errors[0] : null"
      >
        <WrInput v-model="internalValue[0]" label="First Name" type="text" />
      </WrFormGroup>
    </ValidationProvider>
    <ValidationProvider
      v-slot="{ errors }"
      name="Last name"
      :rules="schema.required ? 'required' : ''"
    >
      <WrFormGroup
        help-color="red"
        is-error
        style="flex: 1"
        :help="errors && errors.length ? errors[0] : null"
      >
        <WrInput v-model="internalValue[1]" label="Last name" type="text" />
      </WrFormGroup>
    </ValidationProvider>
  </div>
</template>

<script>
import { WrInput, WrFormGroup } from 'werecover-ui-lib'
import { abstractField } from 'vue-form-generator'

export default {
  components: {
    WrInput,
    WrFormGroup,
  },
  mixins: [abstractField],
  data() {
    return {
      internalValue: ['', ''],
    }
  },
  watch: {
    internalValue(newValue) {
      this.value = newValue
    },
  },
  mounted() {
    if (
      this.formOptions?.inquiryPatient?.firstName ||
      this.formOptions?.inquiryPatient?.lastName
    ) {
      this.value = [
        this.formOptions?.inquiryPatient?.firstName || '',
        this.formOptions?.inquiryPatient?.lastName || '',
      ]
      this.internalValue = [
        this.formOptions?.inquiryPatient?.firstName || '',
        this.formOptions?.inquiryPatient?.lastName || '',
      ]
    }
  },
}
</script>
