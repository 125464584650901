window.onNuxtReady(() => {
  ;(function () {
    window.userpilotSettings = { token: process.env.USER_PILOT_TOKEN }

    var script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = 'https://js.userpilot.io/sdk/latest.js'
    document.body.appendChild(script)
  })()
})
