import Vue from 'vue'
import VueGallery from 'vue-gallery'

const VGallery = {
  install(Vue, options) {
    Vue.component('VueGallery', VueGallery)
  },
}
Vue.use(VGallery)
export default VGallery
