<template>
  <ValidationProvider
    v-slot="{ errors }"
    :name="schema.inputLabel"
    :rules="schema.required ? 'required|min:1' : ''"
  >
    <WrFormGroup
      :key="updateKey"
      help-color="red"
      is-error
      style="flex: 1"
      :help="errors && errors.length ? errors[0] : null"
    >
      <input v-model="value" type="hidden" />
      <WrSoftSelectInput
        :label="schema.inputLabel"
        :selected="value"
        :placeholder="schema.placeholder"
        :options="options"
        multiple
        @onSelect="onSelected"
      />
    </WrFormGroup>
  </ValidationProvider>
</template>

<script>
import { WrSoftSelectInput, WrFormGroup } from 'werecover-ui-lib'
import { abstractField } from 'vue-form-generator'

export default {
  components: {
    WrSoftSelectInput,
    WrFormGroup,
  },
  mixins: [abstractField],
  data() {
    return {
      updateKey: '',
    }
  },
  computed: {
    options() {
      return (
        this.schema?.options?.map((option) => ({
          ...option,
          name: option.description,
          id: String(option.id),
        })) || []
      )
    },
  },
  watch: {
    value: {
      handler(value) {
        if (typeof value === 'string') {
          this.value = JSON.parse(value)
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.updateKey = this.uuid()
  },
  methods: {
    onSelected(options) {
      this.value = options
      this.updateKey = this.uuid()
    },
    uuid() {
      var d = new Date().getTime()
      if (
        typeof performance !== 'undefined' &&
        typeof performance.now === 'function'
      ) {
        d += performance.now() // use high-precision timer if available
      }
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
        /[xy]/g,
        function (c) {
          var r = (d + Math.random() * 16) % 16 | 0
          d = Math.floor(d / 16)
          return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
        },
      )
    },
  },
}
</script>
