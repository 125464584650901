export default (context) => {
  if (!context.route.name?.includes('sign-token')) {
    const scriptTag = document.createElement('script')
    scriptTag.src = '//js.hsforms.net/forms/shell.js'
    scriptTag.type = 'text/javascript'
    scriptTag.async = true
    scriptTag.defer = true

    const scriptTag2 = document.createElement('script')
    scriptTag2.src = `//js.hs-scripts.com/${process.env.HUBSPOT_PORTAL_ID}.js`
    scriptTag2.type = 'text/javascript'
    scriptTag2.async = true
    scriptTag2.defer = true

    document.head.appendChild(scriptTag)
    document.head.appendChild(scriptTag2)
  }
}
