<template>
  <ValidationProvider
    v-slot="{ errors }"
    name="signature"
    :rules="schema.required ? 'required' : ''"
  >
    <WrFormGroup
      help-color="red"
      is-error
      style="flex: 1"
      :help="errors && errors.length ? errors[0] : null"
    >
      <WrText size="small" weight="bold">{{ schema.inputLabel }}</WrText>
      <input v-model="value" type="hidden" />
      <VueSignaturePad
        v-show="!name"
        ref="signaturePad"
        width="100%"
        height="200px"
        :custom-style="{ border: 'black 2px solid' }"
        :options="{ onEnd }"
      />
      <canvas
        v-show="name"
        id="signature-pad"
        ref="signaturePadFromName"
        style="border: black 2px solid; width: 100%; height: 200px"
      ></canvas>
      <div class="bottom-section">
        <WrButton size="medium" color="light-grey" @click="clear">
          <WrMaterialIcon name="delete" color="primary" material />
        </WrButton>
        <WrButton
          size="medium"
          color="light-grey"
          style="margin-left: 5px"
          @click="undo"
        >
          <WrMaterialIcon name="undo" color="primary" material />
        </WrButton>
        <WrInput
          v-model="name"
          placeholder="Your name"
          label="Or enter your name here"
          style="margin-left: 5px; width: 100%"
        />
      </div>
    </WrFormGroup>
  </ValidationProvider>
</template>

<script>
import {
  WrFormGroup,
  WrText,
  WrButton,
  WrMaterialIcon,
  WrInput,
} from 'werecover-ui-lib'
import { abstractField } from 'vue-form-generator'

export default {
  components: {
    WrFormGroup,
    WrText,
    WrButton,
    WrMaterialIcon,
    WrInput,
  },
  mixins: [abstractField],
  data() {
    return {
      name: null,
    }
  },
  head: {
    link: [
      {
        rel: 'stylesheet',
        href: 'https://fonts.googleapis.com/css?family=Dancing+Script',
      },
    ],
  },
  watch: {
    name(val) {
      const context = this.$refs.signaturePadFromName.getContext('2d')
      context.font = '36px Dancing Script'
      context.clearRect(0, 0, 300, 200)
      context.fillText(val, 30, 80, 250)
      const data = this.$refs.signaturePadFromName.toDataURL('image/png')
      this.value = data
    },
  },
  methods: {
    undo() {
      this.$refs.signaturePad.undoSignature()
      const { data } = this.$refs.signaturePad.saveSignature()
      this.value = data
    },
    clear() {
      this.$refs.signaturePad.clearSignature()
      this.value = null
    },
    onEnd() {
      const { data } = this.$refs.signaturePad.saveSignature()
      this.value = data
    },
  },
}
</script>
<style scoped lang="scss">
.bottom-section {
  display: flex;
  align-items: center;
  padding-top: 5px;
}
</style>
