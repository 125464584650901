import { render, staticRenderFns } from "./fieldWrInput.vue?vue&type=template&id=04c2bade&"
import script from "./fieldWrInput.vue?vue&type=script&lang=js&"
export * from "./fieldWrInput.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports