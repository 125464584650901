<template>
  <input v-model="value" type="hidden" />
</template>

<script>
import { abstractField } from 'vue-form-generator'
import Cookies from 'js-cookie'

export default {
  mixins: [abstractField],
  mounted() {
    // get query params from url
    const urlParams = new URLSearchParams(window.location.search)
    const myParam = urlParams.get(this.schema.inputLabel)
    if (myParam) {
      this.value = myParam
    }

    // get query params from nuxt
    if (this.$route.query[this.schema.inputLabel]) {
      this.value = this.$route.query[this.schema.inputLabel]
    }

    // get param from other sources
    switch (this.schema.source) {
      case 'local-storage':
        const lsValue = localStorage.getItem(this.schema.inputLabel)
        if (lsValue) {
          this.value = lsValue
        }
        break
      case 'session-storage':
        const ssValue = sessionStorage.getItem(this.schema.inputLabel)
        if (ssValue) {
          this.value = ssValue
        }
        break
      case 'cookies':
        const cValue = Cookies.get(this.schema.inputLabel)
        if (cValue) {
          this.value = cValue
        }
        break
      default:
        break
    }
  },
}
</script>
