export default {
  created() {
    window.addEventListener('resize', this.trackWindowSizeChange)
    this.trackWindowSizeChange()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.trackWindowSizeChange)
  },
  data() {
    return {
      trackWindowSize: {
        browserWidth: 1000,
      },
    }
  },
  computed: {
    isMobile() {
      return this.trackWindowSize.browserWidth <= 768
    },
  },
  methods: {
    trackWindowSizeChange() {
      this.trackWindowSize.browserWidth = window.innerWidth
    },
  },
}
