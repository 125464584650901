/* eslint-disable */
import Vue from 'vue'
import { isMobileApp } from '~/helpers/mobile-app'
import debounce from 'lodash.debounce'

export default ({ app }) => {
  if (isMobileApp()) {
    initForCapacitor(app)
  } else {
    initForWeb(app)
  }
}

function initForWeb(app) {
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  // on some pages we have immediate redirects
  const gtaCall = debounce(function (page_path, page_title) {
    // https://developers.google.com/analytics/devguides/collection/gtagjs/pages
    gtag('event', 'page_view', {
      page_path: page_path,
      page_title: page_title,
    })
  }, 300)


  // w/o send_page_view ga4 tries to manipulate with browser history that breaks vue
  // https://developers.google.com/analytics/devguides/collection/gtagjs#disable_pageview_measurement
  process.env.GOOGLE_ANALYTICS_4_WEB_MID &&
    gtag('config', process.env.GOOGLE_ANALYTICS_4_WEB_MID, {'send_page_view': false});
  app.router.afterEach((to, from) => {
    gtaCall(to.fullPath, to.name)
  })

  Vue.prototype.$gtag = Vue.$gtag = {
    event (action, event_category, event_label) {
      if (gtag) gtag('event', action, {event_category, event_label})
    },
    setUserProperties( data = {}) {
      if (gtag) gtag('set', 'user_properties', data)
    }
  };
}

function initForCapacitor(app) {
  const { Plugins } = require('@capacitor/core')
  const { Ga4Plugin } = Plugins

  // on some pages we have immediate redirects
  const ga4eventCall = debounce(function (screen_name) {
    Ga4Plugin.event({
      event: 'screen_view',
      data: {
        screen_name: screen_name,
      },
    })
  }, 300)

  app.router.afterEach((to, from) => {
    ga4eventCall(to.name)
  })

  Vue.prototype.$gtag = Vue.$gtag = {
    event (action, event_category, event_label) {
      Ga4Plugin.event({
        event: action,
        data: { event_category, event_label },
      })
    },
    setUserProperties(data = {}) {
      Ga4Plugin.setUserProperties({
        data,
      })
    }
  };
}
