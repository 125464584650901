import Vue from 'vue'

export default () => {
  window.pusherClient = Vue.prototype.$pusher
  const feChannel = window.pusherClient.subscribe('frontend-updates')
  feChannel.bind('update-app', (data) => {
    if (data.force_logout) return (window.location.href = '/logout')
    if (data.force_reload) return window.location.reload()
  })
}
